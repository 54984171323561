<template>
  <div class="container">
    <div class="form">
      <h2 class="headline">Wir nehmen uns Zeit für dich!</h2>
      <form @submit.prevent="clicked()">
        <label for="name" class="label">Name:</label>
        <br />
        <input
          class="input"
          type="text"
          id="name"
          name="name"
          v-model="data.name"
        /><br /><br />
        <label class="label" for="mail">E-Mail:</label>
        <br />
        <input
          class="input"
          type="email"
          id="mail"
          name="mail"
          v-model="data.email"
          required
        /><br /><br />
        <label class="label" for="phone">Telefon:</label>
        <br />
        <input
          class="input"
          ref="my_input"
          type="text"
          v-model="data.phone"
          id="phone"
          name="phone"
        /><br /><br />
        <label class="label" for="message">Deine Nachricht:</label><br />
        <textarea
          class="input-textarea"
          style="height: 100px; resize: none; margin-bottom:1.8rem"
          v-model="data.message"
          id="message"
          name="message"
        />
        <br />
        <label class=" label label-agree"
          >Indem du auf „Abschicken“ klickst, stimmst du unserer
          <router-link to="/datenschutz">Datenschutzrichtlinie</router-link>
          zu.</label
        ><br />
        <input class="button" v-if="!send" type="submit" value="Abschicken" />
        <input
          class="button"
          style="background-color: #5cb85c"
          v-if="send"
          type="submit"
          :disabled="true"
          value="Nachricht versendet"
        />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  components: {},
  data() {
    return {
      send: false,
      status: 200,
      reportConversion: "",
      data: {
        name: "",
        email: null,
        phone: null,
        message: ""
      }
    };
  },
  methods: {
    clicked() {
      if (!this.send) {
        this.axios
          .post("/mailer.php", this.data)
          .then(response => (this.status = response.status));
        this.send = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@asigloo/vue-dynamic-forms/src/styles/themes/default.scss";
.headline {
  text-align: center;
  letter-spacing: 0;
  color: #254281;
  line-height: 2rem;
  margin: 2rem 1rem;
  font-size: 2rem;

  @media screen and(min-width: 1024px) {
    font-size: 2.5rem;
  }
}

.form {
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.container {
  background-color: #ebebeb;
  display: flex;
  height: auto;
  align-items: center;
  width: 100%;
  padding: 8% 0;
  @media screen and (min-width: 1260px) {
    height: 100vh;
  }
}

.label {
  float: left;
  margin-left: 25%;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;

  &-agree {
    font-size: 0.8rem;
    text-align: center;
    margin-right: 25%;
    font-weight: normal;
  }

  @media screen and(min-width: 1024px) {
    margin-left: 32.5%;
  }
}
.input {
  width: 50%;
  height: 35px;
  border-radius: 6px;
  border: none;

  @media screen and(min-width: 1024px) {
    width: 35%;
  }

  &-textarea {
    border-radius: 8px;
    width: 50%;
    border-color: antiquewhite;
    @media screen and(min-width: 1024px) {
      width: 35%;
    }
  }
}

.button {
  background-color: #254281;
  border: none;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50px;
  min-width: 200px;
  margin: 2rem auto;
}
</style>
