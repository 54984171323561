<template>
  <div class="container" id="jump">
    <div class="wrapper">
      <div>
        <h2 class="headline">Arthora - die Kunst der Zeit</h2>
        <p class="text text-margin">
          Modernes Design, hochwertige Materialien und individuelle
          Konfigurierbarkeit - ARTHORA bietet exklusive Uhren für einzigartige
          Menschen. Die Armbanduhren von ARTHORA im Detail:
        </p>

        <div class="list" v-if="windowWidth > 1023">
          <p>- Uhrwerk vom schweizer Hersteller Ronda</p>
          <p>- hochwertiges Edelstahl-Gehäuse</p>
          <p>- massive Stahl-Ziffernblätter mit Damaststahl-Struktur</p>
          <p>- kratzfestes Saphirglas</p>
          <p>- verschraubte Komponenten</p>
          <p>
            - wasserfest bis 200 Meter Tiefe
          </p>
          <p>- Made in Germany</p>
          <p>- Superluminova</p>
          <p>- 864 Kombinationen</p>
        </div>
        <div class="text" style="text-align: center" v-else>
          <p>Uhrwerk vom schweizer Hersteller Ronda</p>
          <p class="divider">–</p>
          <p>hochwertiges Edelstahl-Gehäuse</p>
          <p class="divider">–</p>
          <p>massive Stahl-Ziffernblätter mit Damaststahl-Struktur</p>
          <p class="divider">–</p>
          <p>kratzfestes Saphirglas</p>
          <p class="divider">–</p>
          <p>verschraubte Komponenten</p>
          <p class="divider">–</p>
          <p>wasserfest bis 200 Meter Tiefe</p>
          <p class="divider">–</p>
          <p>Made in Germany</p>
          <p class="divider">–</p>
          <p>Superluminova</p>
          <p class="divider">–</p>
          <p>864 Kombinationen</p>
        </div>

        <p class="text text-margin-top">
          Bleib auf dem Laufenden, melde dich jetzt zu unserem Newsletter an und
          sichere dir 10 € Rabatt!
        </p>
        <div class="subscribe-container">
          <div :id="elementId + 'mc_embed_signup'">
            <!--          Change the :action url to your mailchimp form  -->
            <form
              :action="
                'https://arthora.us2.list-manage.com/subscribe/post?u=c0816ce313d051d2b6db4ef91&amp;id=aebf801c1f'
              "
              method="post"
              :id="elementId + 'mc-embedded-subscribe-form'"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
            >
              <div :id="elementId + 'mc_embed_signup_scroll'">
                <input
                  type="email"
                  name="EMAIL"
                  class="subscribe-container__email"
                  placeholder=" Deine E-Mail Adresse..."
                  required
                />
                <div
                  style="position: absolute; left: -5000px;"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_5f0b91c96bbdf35913a136639_ddfba3375e"
                    tabIndex="-1"
                  />
                </div>
                <div class="agree">
                  <p>
                    Indem du auf „Anmelden“ klickst, stimmst du unserer
                    <router-link to="/datenschutz"
                      >Datenschutzrichtlinie</router-link
                    >
                    zu.
                  </p>
                </div>
                <div>
                  <button
                    type="submit"
                    name="subscribe"
                    class="subscribe-container__submit"
                  >
                    Anmelden
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <video
      autoplay
      muted
      loop
      playsinline
      src="../assets/animation.mp4"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Newsletter",
  data() {
    return {
      elementId: "",
      url: "",
      title: "",
      subtitle: ""
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
.agree {
  font-size: 0.8rem;
}
.divider {
  color: #254281;
  font-weight: bold;
  font-size: 2rem;
  margin: auto;
  line-height: 1.2rem;
  border-radius: 10px;
}
.headline {
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 0;
  color: #254281;
  line-height: 2rem;
  margin: 0 auto;
  text-transform: uppercase;
  @media screen and(min-width: 1024px) {
    text-align: left;
    font-size: 2.5rem;
  }
}

.text {
  text-align: center;
  color: #0f0f0f;
  font-weight: bold;
  margin-bottom: 2.5rem;
  font-family: "Ubuntu", sans-serif;
  @media screen and(min-width: 1024px) {
    text-align: left;
  }
  &-margin {
    @media screen and(min-width: 1260px) {
      margin: 2.5rem 0;
    }
  }
  &-margin-top {
    margin: 2.5rem auto;
    @media screen and(min-width: 1260px) {
      margin-top: 5rem;
    }
  }
}

.list {
  text-align: left;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  @media screen and(min-width: 768px) {
    line-height: 1.2rem;
  }
  @media screen and(min-width: 1024px) {
    line-height: 1.2rem;
  }
}

.subscribe-container {
  text-align: center;
  @media screen and(min-width: 1024px) {
    display: flex;
    align-items: flex-start;
  }

  form {
  }

  &__title {
  }

  &__subtitle {
  }

  &__email {
    min-width: 50%;
    height: 2.5rem;
    line-height: 2rem;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #ebebeb;
    padding-left: 0.5rem;
    user-focus: none;
    border: none;
    margin-bottom: 0.5rem;
    @media screen and(min-width: 768px) {
      width: 90%;
    }
  }
  #mc_embed_signup {
    @media screen and(min-width: 768px) {
      width: 100%;
      text-align: center;
    }
    @media screen and(min-width: 1024px) {
      width: 70%;
      text-align: left;
    }
  }

  &__submit {
    background-color: #254281;
    border: none;
    color: white;
    padding: 15px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 50px;
    margin-top: 10px;
    min-width: 200px;
    @media screen and (min-width: 1024px) {
      float: left;
    }
    &:hover {
    }
  }
}
.subscribe-container__submit {
}

.container {
  width: 90%;
  margin: 1rem auto;

  @media screen and (min-width: 767px) {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @media screen and (min-width: 1260px) {
    height: 100vh;
  }
}
video {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 30%;
    height: auto;
  }
}
.wrapper {
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    max-width: 75%;
    justify-content: center;
    align-items: center;
  }
}
</style>
