<template>
  <div class="container">
    <h1>Datenschutz­erklärung</h1>
    <h2>1. Datenschutz auf einen Blick</h2>
    <h3>Allgemeine Hinweise</h3>
    <p>
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
      Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
      Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
      identifiziert werden können. Ausführliche Informationen zum Thema
      Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
      Datenschutzerklärung.
    </p>
    <h3>Datenerfassung auf dieser Website</h3>
    <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
    <p>
      Die Datenverarbeitung auf dieser Website erfolgt durch den
      Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
      Website entnehmen.
    </p>
    <h4>Wie erfassen wir Ihre Daten?</h4>
    <p>
      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
      Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
      eingeben.
    </p>
    <p>
      Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
      der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische
      Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
      Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie
      diese Website betreten.
    </p>
    <h4>Wofür nutzen wir Ihre Daten?</h4>
    <p>
      Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
      Website zu gewährleisten. Andere Daten können zur Analyse Ihres
      Nutzerverhaltens verwendet werden.
    </p>
    <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
    <p>
      Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
      Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
      erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
      dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
      Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
      für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten
      Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
      zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der
      zuständigen Aufsichtsbehörde zu.
    </p>
    <p>
      Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
      jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
    </p>
    <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>
    <p>
      Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet
      werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.
    </p>
    <p>
      Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
      folgenden Datenschutzerklärung.
    </p>
    <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
    <h3>Externes Hosting</h3>
    <p>
      Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die
      personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf
      den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
      IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
      Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
      die über eine Website generiert werden, handeln.
    </p>
    <p>
      Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber
      unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO)
      und im Interesse einer sicheren, schnellen und effizienten Bereitstellung
      unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs.
      1 lit. f DSGVO).
    </p>
    <p>
      Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
      Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen
      in Bezug auf diese Daten befolgen.
    </p>
    <p>Wir setzen folgenden Hoster ein:</p>
    <p>
      STRATO AG <br />
      Pascalstraße 10<br />
      10587 Berlin<br />
    </p>
    <h4>Abschluss eines Vertrages über Auftragsverarbeitung</h4>
    <p>
      Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen
      Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.
    </p>
    <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
    <h3>Datenschutz</h3>
    <p>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
      sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
      entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
      Datenschutzerklärung.
    </p>
    <p>
      Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
      Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
      identifiziert werden können. Die vorliegende Datenschutzerklärung
      erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
      erläutert auch, wie und zu welchem Zweck das geschieht.
    </p>
    <p>
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
      Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
      lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
      möglich.
    </p>
    <h3>Hinweis zur verantwortlichen Stelle</h3>
    <p>
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
      ist:
    </p>
    <p>
      Tim Krause<br />
      ARTHORA<br />
      Am Gosekamp 29<br />
      45549 Sprockhövel
    </p>

    <p>
      Telefon: 01772419434<br />
      E-Mail: info@arthora.de
    </p>
    <p>
      Verantwortliche Stelle ist die natürliche oder juristische Person, die
      allein oder gemeinsam mit anderen über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
      Ä.) entscheidet.
    </p>

    <h3>Speicherdauer</h3>
    <p>
      Soweit innerhalb dieser Datenschutzerklärung keine speziellere
      Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
      uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
      berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
      Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
      keinen anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
      personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
      Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
      Fortfall dieser Gründe.
    </p>
    <h3>Hinweis zur Datenweitergabe in die USA</h3>
    <p>
      Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in
      den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre
      personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
      weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer
      Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind
      dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden
      herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich
      vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass
      US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
      Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir
      haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
    </p>
    <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
    <p>
      Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
      Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
      jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
      Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>
    <h3>
      Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
      Direktwerbung (Art. 21 DSGVO)
    </h3>
    <p>
      WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
      DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
      IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
      PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
      DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE,
      AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
      DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE
      BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN,
      WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN,
      DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
      VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
      RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
    </p>
    <p>
      WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
      BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
      VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
      DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES
      MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN,
      WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE
      DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
    </p>
    <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
    <p>
      Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
      Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
      Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
      des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
      unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
      Rechtsbehelfe.
    </p>
    <h3>Recht auf Daten­übertrag­barkeit</h3>
    <p>
      Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder
      in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
      einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu
      lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
      Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
      ist.
    </p>
    <h3>SSL- bzw. TLS-Verschlüsselung</h3>
    <p>
      Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
      vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
      Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung.
      Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
      des Browsers von „http://“ auf „https://“ wechselt und an dem
      Schloss-Symbol in Ihrer Browserzeile.
    </p>
    <p>
      Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
      die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
    </p>
    <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
    <p>
      Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
      Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei
      Einzugsermächtigung) zu übermitteln, werden diese Daten zur
      Zahlungsabwicklung benötigt.
    </p>
    <p>
      Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard,
      Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte SSL-
      bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran,
      dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
      und an dem Schloss-Symbol in Ihrer Browserzeile.
    </p>
    <p>
      Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an
      uns übermitteln, nicht von Dritten mitgelesen werden.
    </p>
    <h3>Auskunft, Löschung und Berichtigung</h3>
    <p>
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser
      Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
      können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
      uns wenden.
    </p>
    <h3>Recht auf Einschränkung der Verarbeitung</h3>
    <p>
      Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
      unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
      Einschränkung der Verarbeitung besteht in folgenden Fällen:
    </p>
    <ul>
      <li>
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
        Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
        überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </li>
      <li>
        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
        geschah/geschieht, können Sie statt der Löschung die Einschränkung der
        Datenverarbeitung verlangen.
      </li>
      <li>
        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
        jedoch zur Ausübung, Verteidigung oder Geltendmachung von
        Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </li>
      <li>
        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
        muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
        werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
        haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen.
      </li>
    </ul>
    <p>
      Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
      haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
      Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
      Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
      juristischen Person oder aus Gründen eines wichtigen öffentlichen
      Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
      werden.
    </p>
    <h3>Widerspruch gegen Werbe-E-Mails</h3>
    <p>
      Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
      Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung
      und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
      Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
      unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails,
      vor.
    </p>
    <h2>4. Datenerfassung auf dieser Website</h2>
    <h3>Cookies</h3>
    <p>
      Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine
      Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden
      entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder
      dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert.
      Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht.
      Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
      selbst löschen oder eine automatische Löschung durch Ihren Webbrowser
      erfolgt.
    </p>
    <p>
      Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
      gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies).
      Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen
      des Drittunternehmens (z.B. Cookies zur Abwicklung von
      Zahlungsdienstleistungen).
    </p>
    <p>
      Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
      notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
      würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
      Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
      anzuzeigen.
    </p>
    <p>
      Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
      (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen
      erwünschter Funktionen (funktionale Cookies, z. B. für die
      Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur
      Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art.
      6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage
      angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der
      Speicherung von Cookies zur technisch fehlerfreien und optimierten
      Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung
      von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden
      Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
    </p>
    <p>
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
      Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
      Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
      das automatische Löschen der Cookies beim Schließen des Browsers
      aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
      dieser Website eingeschränkt sein.
    </p>
    <p>
      Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
      werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
      gesondert informieren und ggf. eine Einwilligung abfragen.
    </p>
    <h3>Server-Log-Dateien</h3>
    <p>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in
      so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
      übermittelt. Dies sind:
    </p>
    <ul>
      <li>Browsertyp und Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
      <li>IP-Adresse</li>
    </ul>
    <p>
      Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
      vorgenommen.
    </p>
    <p>
      Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
      DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
      technisch fehlerfreien Darstellung und der Optimierung seiner Website –
      hierzu müssen die Server-Log-Files erfasst werden.
    </p>
    <h3>Kontaktformular</h3>
    <p>
      Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
      Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
      Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
      Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
      Einwilligung weiter.
    </p>
    <p>
      Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
      b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
      zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
      erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
      unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
      gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
      Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
    </p>
    <p>
      Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
      bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
      widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
      abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
      Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
    </p>
    <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
    <p>
      Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
      Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
      (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
      gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
      Einwilligung weiter.
    </p>
    <p>
      Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
      b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
      zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
      erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
      unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
      gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
      Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
    </p>
    <p>
      Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei
      uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
      Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
      (z.B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
      gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen –
      bleiben unberührt.
    </p>
    <h3>Registrierung auf dieser Website</h3>
    <p>
      Sie können sich auf dieser Website registrieren, um zusätzliche Funktionen
      auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum
      Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie
      sich registriert haben. Die bei der Registrierung abgefragten
      Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden
      wir die Registrierung ablehnen.
    </p>
    <p>
      Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
      notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
      E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
    </p>
    <p>
      Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum
      Zwecke der Durchführung des durch die Registrierung begründeten
      Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6
      Abs. 1 lit. b DSGVO).
    </p>
    <p>
      Die bei der Registrierung erfassten Daten werden von uns gespeichert,
      solange Sie auf dieser Website registriert sind und werden anschließend
      gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
    </p>
    <h2>5. Analyse-Tools und Werbung</h2>
    <h3>Google Analytics</h3>
    <p>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
      Street, Dublin 4, Irland.
    </p>
    <p>
      Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
      Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
      verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer,
      verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden
      von Google ggf. in einem Profil zusammengefasst, das dem jeweiligen Nutzer
      bzw. dessen Endgerät zugeordnet ist.
    </p>
    <p>
      Google Analytics verwendet Technologien, die die Wiedererkennung des
      Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.B.
      Cookies oder Device-Fingerprinting). Die von Google erfassten
      Informationen über die Benutzung dieser Website werden in der Regel an
      einen Server von Google in den USA übertragen und dort gespeichert.
    </p>
    <p>
      Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1
      lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
      Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
      Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt
      wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
      Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
      die Einwilligung ist jederzeit widerrufbar.
    </p>
    <p>
      Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
      EU-Kommission gestützt. Details finden Sie hier:
      <a
        href="https://privacy.google.com/businesses/controllerterms/mccs/"
        target="_blank"
        rel="noopener noreferrer"
        >https://privacy.google.com/businesses/controllerterms/mccs/</a
      >.
    </p>
    <h4>IP Anonymisierung</h4>
    <p>
      Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
      Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
      Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
      Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur
      in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
      den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
      Website wird Google diese Informationen benutzen, um Ihre Nutzung der
      Website auszuwerten, um Reports über die Websiteaktivitäten
      zusammenzustellen und um weitere mit der Websitenutzung und der
      Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber
      zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
      übermittelte IP-Adresse wird nicht mit anderen Daten von Google
      zusammengeführt.
    </p>
    <h4>Browser Plugin</h4>
    <p>
      Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
      verhindern, indem Sie das unter dem folgenden Link verfügbare
      Browser-Plugin herunterladen und installieren:
      <a
        href="https://tools.google.com/dlpage/gaoptout?hl=de"
        target="_blank"
        rel="noopener noreferrer"
        >https://tools.google.com/dlpage/gaoptout?hl=de</a
      >.
    </p>
    <p>
      Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden
      Sie in der Datenschutzerklärung von Google:
      <a
        href="https://support.google.com/analytics/answer/6004245?hl=de"
        target="_blank"
        rel="noopener noreferrer"
        >https://support.google.com/analytics/answer/6004245?hl=de</a
      >.
    </p>
    <h4>Auftragsverarbeitung</h4>
    <p>
      Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen
      und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der
      Nutzung von Google Analytics vollständig um.
    </p>
    <h4>Demografische Merkmale bei Google Analytics</h4>
    <p>
      Diese Website nutzt die Funktion „demografische Merkmale“ von Google
      Analytics, um den Websitebesuchern passende Werbeanzeigen innerhalb des
      Google-Werbenetzwerks anzeigen zu können. Dadurch können Berichte erstellt
      werden, die Aussagen zu Alter, Geschlecht und Interessen der
      Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener
      Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
      können keiner bestimmten Person zugeordnet werden. Sie können diese
      Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto
      deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im
      Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.
    </p>
    <h4>Google Analytics E-Commerce-Tracking</h4>
    <p>
      Diese Website nutzt die Funktion „E-Commerce-Tracking“ von Google
      Analytics. Mit Hilfe von E-Commerce-Tracking kann der Websitebetreiber das
      Kaufverhalten der Websitebesucher zur Verbesserung seiner
      Online-Marketing-Kampagnen analysieren. Hierbei werden Informationen, wie
      zum Beispiel die getätigten Bestellungen, durchschnittliche Bestellwerte,
      Versandkosten und die Zeit von der Ansicht bis zum Kauf eines Produktes
      erfasst. Diese Daten können von Google unter einer Transaktions-ID
      zusammengefasst werden, die dem jeweiligen Nutzer bzw. dessen Gerät
      zugeordnet ist.
    </p>
    <h4>Speicherdauer</h4>
    <p>
      Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
      Cookies, Nutzerkennungen (z.B. User ID) oder Werbe-IDs (z.B.
      DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 50
      Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter
      folgendem Link:
      <a
        href="https://support.google.com/analytics/answer/7667196?hl=de"
        target="_blank"
        rel="noopener noreferrer"
        >https://support.google.com/analytics/answer/7667196?hl=de</a
      >
    </p>

    <h3>Google Remarketing</h3>
    <p>
      Diese Website nutzt die Funktionen von Google Analytics Remarketing.
      Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
      Street, Dublin 4, Irland.
    </p>
    <p>
      Google Remarketing analysiert Ihr Nutzerverhalten auf unserer Website
      (z.B. Klick auf bestimmte Produkte), um Sie in bestimmte Werbe-Zielgruppen
      einzuordnen und Ihnen anschließend beim Besuch von anderen Onlineangeboten
      passende Webebotschaften auszuspielen (Remarketing bzw. Retargeting).
    </p>
    <p>
      Des Weiteren können die mit Google Remarketing erstellten
      Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von Google
      verknüpft werden. Auf diese Weise können interessenbezogene,
      personalisierte Werbebotschaften, die in Abhängigkeit Ihres früheren
      Nutzungs- und Surfverhaltens auf einem Endgerät (z. B. Handy) an Sie
      angepasst wurden auch auf einem anderen Ihrer Endgeräte (z. B. Tablet oder
      PC) angezeigt werden.
    </p>
    <p>
      Wenn Sie über einen Google-Account verfügen, können Sie der
      personalisierten Werbung unter folgendem Link widersprechen:
      <a
        href="https://www.google.com/settings/ads/onweb/"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.google.com/settings/ads/onweb/</a
      >.
    </p>
    <p>
      Die Nutzung von Google Remarketing erfolgt auf Grundlage von Art. 6 Abs. 1
      lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
      möglichst effektiven Vermarktung seiner Produkte. Sofern eine
      entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
      ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
      Einwilligung ist jederzeit widerrufbar.
    </p>
    <p>
      Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in
      der Datenschutzerklärung von Google unter:
      <a
        href="https://policies.google.com/technologies/ads?hl=de"
        target="_blank"
        rel="noopener noreferrer"
        >https://policies.google.com/technologies/ads?hl=de</a
      >.
    </p>
    <h4>Zielgruppenbildung mit Kundenabgleich</h4>
    <p>
      Zur Zielgruppenbildung verwenden wir unter anderem den Kundenabgleich von
      Google Remarketing. Hierbei übergeben wir bestimmte Kundendaten (z.B.
      E-Mail-Adressen) aus unseren Kundenlisten an Google. Sind die betreffenden
      Kunden Google-Nutzer und in ihrem Google-Konto eingeloggt, werden ihnen
      passende Werbebotschaften innerhalb des Google-Netzwerks (z.B. bei
      YouTube, Gmail oder in der Suchmaschine) angezeigt.
    </p>
    <h3>Google Conversion-Tracking</h3>
    <p>
      Diese Website nutzt Google Conversion Tracking. Anbieter ist die Google
      Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
    </p>
    <p>
      Mit Hilfe von Google-Conversion-Tracking können Google und wir erkennen,
      ob der Nutzer bestimmte Aktionen durchgeführt hat. So können wir
      beispielsweise auswerten, welche Buttons auf unserer Website wie häufig
      geklickt und welche Produkte besonders häufig angesehen oder gekauft
      wurden. Diese Informationen dienen dazu, Conversion-Statistiken zu
      erstellen. Wir erfahren die Gesamtanzahl der Nutzer, die auf unsere
      Anzeigen geklickt haben und welche Aktionen sie durchgeführt haben. Wir
      erhalten keine Informationen, mit denen wir den Nutzer persönlich
      identifizieren können. Google selbst nutzt zur Identifikation Cookies oder
      vergleichbare Wiedererkennungstechnologien.
    </p>
    <p>
      Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage von Art.
      6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
      an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
      seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung
      abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
      erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1
      lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
    </p>
    <p>
      Mehr Informationen zu Google Conversion-Tracking finden Sie in den
      Datenschutzbestimmungen von Google:
      <a
        href="https://policies.google.com/privacy?hl=de"
        target="_blank"
        rel="noopener noreferrer"
        >https://policies.google.com/privacy?hl=de</a
      >.
    </p>
    <h3>Facebook Pixel</h3>
    <p>
      Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von
      Facebook. Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4
      Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach
      Aussage von Facebook jedoch auch in die USA und in andere Drittländer
      übertragen.
    </p>
    <p>
      So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem
      diese durch Klick auf eine Facebook-Werbeanzeige auf die Website des
      Anbieters weitergeleitet wurden. Dadurch können die Wirksamkeit der
      Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
      ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.
    </p>
    <p>
      Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir
      können keine Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten
      werden aber von Facebook gespeichert und verarbeitet, sodass eine
      Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook die Daten
      für eigene Werbezwecke, entsprechend der
      <a
        href="https://de-de.facebook.com/about/privacy/"
        target="_blank"
        rel="noopener noreferrer"
        >Facebook-Datenverwendungsrichtlinie</a
      >
      verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf
      Seiten von Facebook sowie außerhalb von Facebook ermöglichen. Diese
      Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst
      werden.
    </p>
    <p>
      Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6 Abs. 1
      lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
      effektiven Werbemaßnahmen unter Einschluss der sozialen Medien. Sofern
      eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
      zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf
      Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
      widerrufbar.
    </p>
    <p>
      Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
      EU-Kommission gestützt. Details finden Sie hier:
      <a
        href="https://www.facebook.com/legal/EU_data_transfer_addendum"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.facebook.com/legal/EU_data_transfer_addendum</a
      >
      und
      <a
        href="https://de-de.facebook.com/help/566994660333381"
        target="_blank"
        rel="noopener noreferrer"
        >https://de-de.facebook.com/help/566994660333381</a
      >.
    </p>
    <p>
      In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum
      Schutz Ihrer Privatsphäre:
      <a
        href="https://de-de.facebook.com/about/privacy/"
        target="_blank"
        rel="noopener noreferrer"
        >https://de-de.facebook.com/about/privacy/</a
      >.
    </p>
    <p>
      Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im Bereich
      Einstellungen für Werbeanzeigen unter
      <a
        href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a
      >
      deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
    </p>
    <p>
      Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte Werbung
      von Facebook auf der Website der European Interactive Digital Advertising
      Alliance deaktivieren:
      <a
        href="http://www.youronlinechoices.com/de/praferenzmanagement/"
        target="_blank"
        rel="noopener noreferrer"
        >http://www.youronlinechoices.com/de/praferenzmanagement/</a
      >.
    </p>
    <h2>6. Newsletter</h2>
    <h3>Newsletter­daten</h3>
    <p>
      Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
      benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche
      uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
      E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden
      sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben.
      Diese Daten verwenden wir ausschließlich für den Versand der angeforderten
      Informationen und geben diese nicht an Dritte weiter.
    </p>
    <p>
      Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
      erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
      E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie
      jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter. Die
      Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
      Widerruf unberührt.
    </p>
    <p>
      Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten
      werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem
      Newsletterdiensteanbieter gespeichert und nach der Abbestellung des
      Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu
      anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.
    </p>
    <p>
      Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
      E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
      Blacklist gespeichert, um künftige Mailings zu verhindern. Die Daten aus
      der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen
      Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem
      Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von
      Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f
      DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.
      <strong
        >Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
        berechtigtes Interesse überwiegen.</strong
      >
    </p>
    <h3>MailChimp</h3>
    <p>
      Diese Website nutzt die Dienste von MailChimp für den Versand von
      Newslettern. Anbieter ist die Rocket Science Group LLC, 675 Ponce De Leon
      Ave NE, Suite 5000, Atlanta, GA 30308, USA.
    </p>
    <p>
      MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern
      organisiert und analysiert werden kann. Wenn Sie Daten zum Zwecke des
      Newsletterbezugs eingeben (z.B. E-Mail-Adresse), werden diese auf den
      Servern von MailChimp in den USA gespeichert.
    </p>
    <p>
      Mit Hilfe von MailChimp können wir unsere Newsletterkampagnen analysieren.
      Wenn Sie eine mit MailChimp versandte E-Mail öffnen, verbindet sich eine
      in der E-Mail enthaltene Datei (sog. web-beacon) mit den Servern von
      MailChimp in den USA. So kann festgestellt werden, ob eine
      Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden.
      Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt des
      Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Diese Informationen
      können nicht dem jeweiligen Newsletter-Empfänger zugeordnet werden. Sie
      dienen ausschließlich der statistischen Analyse von Newsletterkampagnen.
      Die Ergebnisse dieser Analysen können genutzt werden, um künftige
      Newsletter besser an die Interessen der Empfänger anzupassen.
    </p>
    <p>
      Wenn Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter
      abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen
      entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter
      auch direkt auf der Website abbestellen.
    </p>
    <p>
      Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6
      Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen,
      indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der bereits
      erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
    </p>
    <p>
      Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten
      werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem
      Newsletterdiensteanbieter gespeichert und nach der Abbestellung des
      Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu
      anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.
    </p>
    <p>
      Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
      EU-Kommission gestützt. Details finden Sie hier:
      <a
        href="https://mailchimp.com/legal/data-processing-addendum/#9._Jurisdiction-Specific_Terms"
        target="_blank"
        rel="noopener noreferrer"
        >https://mailchimp.com/legal/data-processing-addendum/#9._Jurisdiction-Specific_Terms</a
      >.
    </p>
    <p>
      Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
      E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
      Blacklist gespeichert, um künftige Mailings zu verhindern. Die Daten aus
      der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen
      Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem
      Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von
      Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f
      DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.
      <strong
        >Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
        berechtigtes Interesse überwiegen.</strong
      >
    </p>
    <p>
      Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp unter:
      <a
        href="https://mailchimp.com/legal/terms/"
        target="_blank"
        rel="noopener noreferrer"
        >https://mailchimp.com/legal/terms/</a
      >.
    </p>
    <h4>Abschluss eines Data-Processing-Agreements</h4>
    <p>
      Wir haben ein sog. „Data-Processing-Agreement“ mit MailChimp
      abgeschlossen, in dem wir MailChimp verpflichten, die Daten unserer Kunden
      zu schützen und sie nicht an Dritte weiterzugeben.
    </p>
    <h2>7. Plugins und Tools</h2>
    <h3>Google Web Fonts</h3>
    <p>
      Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
      genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
      einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
      Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
    </p>
    <p>
      Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
      Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
      dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von
      Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
      Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen
      Darstellung des Schriftbildes auf seiner Website. Sofern eine
      entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
      Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf
      Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
      widerrufbar.
    </p>
    <p>
      Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
      von Ihrem Computer genutzt.
    </p>
    <p>
      Weitere Informationen zu Google Web Fonts finden Sie unter
      <a
        href="https://developers.google.com/fonts/faq"
        target="_blank"
        rel="noopener noreferrer"
        >https://developers.google.com/fonts/faq</a
      >
      und in der Datenschutzerklärung von Google:
      <a
        href="https://policies.google.com/privacy?hl=de"
        target="_blank"
        rel="noopener noreferrer"
        >https://policies.google.com/privacy?hl=de</a
      >.
    </p>
    <h2>8. eCommerce und Zahlungs­anbieter</h2>
    <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
    <p>
      Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie
      für die Begründung, inhaltliche Ausgestaltung oder Änderung des
      Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
      Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
      zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      Personenbezogene Daten über die Inanspruchnahme dieser Website
      (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
      erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
      ermöglichen oder abzurechnen.
    </p>
    <p>
      Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
      Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
      Aufbewahrungsfristen bleiben unberührt.
    </p>
    <h3>
      Daten­übermittlung bei Vertragsschluss für Online-Shops, Händler und
      Warenversand
    </h3>
    <p>
      Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im
      Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der Lieferung
      der Ware betrauten Unternehmen oder das mit der Zahlungsabwicklung
      beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten
      erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich
      zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche
      Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
    </p>
    <p>
      Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
      die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
      vorvertraglicher Maßnahmen gestattet.
    </p>
    <h3>Zahlungsdienste</h3>
    <p>
      Wir binden Zahlungsdienste von Drittunternehmen auf unserer Website ein.
      Wenn Sie einen Kauf bei uns tätigen, werden Ihre Zahlungsdaten (z.B. Name,
      Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom
      Zahlungsdienstleister zum Zwecke der Zahlungsabwicklung verarbeitet. Für
      diese Transaktionen gelten die jeweiligen Vertrags- und
      Datenschutzbestimmungen der jeweiligen Anbieter. Der Einsatz der
      Zahlungsdienstleister erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO
      (Vertragsabwicklung) sowie im Interesse eines möglichst reibungslosen,
      komfortablen und sicheren Zahlungsvorgangs (Art. 6 Abs. 1 lit. f DSGVO).
      Soweit für bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist Art.
      6 Abs. 1 lit. a DSGVO Rechtsgrundlage der Datenverarbeitung;
      Einwilligungen sind jederzeit für die Zukunft widerrufbar.
    </p>
    <p>
      Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im Rahmen
      dieser Website ein:
    </p>
    <h4>PayPal</h4>
    <p>
      Anbieter dieses Zahlungsdienstes ist PayPal (Europe) S.à.r.l. et Cie,
      S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (im Folgenden „PayPal“).
      Details entnehmen Sie der Datenschutzerklärung von PayPal:
      <a
        href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a
      >.
    </p>
    <h4>Sofort­überweisung</h4>
    <p>
      Anbieter dieses Zahlungsdienstes ist die Sofort GmbH, Theresienhöhe 12,
      80339 München (im Folgenden „Sofort GmbH“). Mit Hilfe des Verfahrens
      „Sofortüberweisung“ erhalten wir in Echtzeit eine Zahlungsbestätigung von
      der Sofort GmbH und können unverzüglich mit der Erfüllung unserer
      Verbindlichkeiten beginnen. Wenn Sie sich für die Zahlungsart
      „Sofortüberweisung“ entschieden haben, übermitteln Sie die PIN und eine
      gültige TAN an die Sofort GmbH, mit der diese sich in Ihr
      Online-Banking-Konto einloggen kann. Sofort GmbH überprüft nach dem
      Einloggen automatisch Ihren Kontostand und führt die Überweisung an uns
      mit Hilfe der von Ihnen übermittelten TAN durch. Anschließend übermittelt
      sie uns unverzüglich eine Transaktionsbestätigung. Nach dem Einloggen
      werden außerdem Ihre Umsätze, der Kreditrahmen des Dispokredits und das
      Vorhandensein anderer Konten sowie deren Bestände automatisiert geprüft.
      Neben der PIN und der TAN werden auch die von Ihnen eingegebenen
      Zahlungsdaten sowie Daten zu Ihrer Person an die Sofort GmbH übermittelt.
      Bei den Daten zu Ihrer Person handelt es sich um Vor- und Nachnamen,
      Adresse, Telefonnummer(n), E-Mail-Adresse, IP-Adresse und ggf. weitere zur
      Zahlungsabwicklung erforderliche Daten. Die Übermittlung dieser Daten ist
      notwendig, um Ihre Identität zweifelsfrei zu festzustellen und
      Betrugsversuchen vorzubeugen. Details zur Zahlung mit Sofortüberweisung
      entnehmen Sie folgenden Links:
      <a
        href="https://www.sofort.de/datenschutz.html"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.sofort.de/datenschutz.html</a
      >
      und
      <a
        href="https://www.klarna.com/sofort/"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.klarna.com/sofort/</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: "DataPrivacy"
};
</script>

<style scoped>
.container {
  width: 80%;
  margin: auto;
}
</style>
