<template>
  <div class="home">
    <Header></Header>
    <Newsletter></Newsletter>
    <Form></Form>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Newsletter from "@/components/Newsletter";
import Form from "@/components/Form";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {
    Footer,
    Header,
    Form,
    Newsletter
  }
};
</script>
