<template>
  <div class="footer">
    <div class="footer-wrapper">
      <router-link to="/impressum">Impressum </router-link>
      <router-link to="/datenschutz">Datenschutz</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped lang="scss">
.footer {
  padding-top: 5%;
  width: 30%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 768px) {
    padding: 0;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
    a {
      color: black;
      margin-left: 1rem;
    }
    @media screen and (min-width: 768px) {
      justify-content: space-around;
    }
  }
}
a {
  text-decoration: none;
}
</style>
